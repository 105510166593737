@tailwind base;
@tailwind components;
@tailwind utilities;

body,
* {
  font-family: "Hind", sans-serif;
  box-sizing: border-box;
}
.header-icons {
  height: 8rem;
  width: 8rem;
  margin: 0px auto;
}
.btn-gradiant {
  background-image: linear-gradient(90deg, #434343 0, #0cc3e1 51%, #45ce43);
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border: none;
  /* font-weight: 700; */
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  padding: 10px 12px;
  font-size: 14px;
  min-width: 110px;
}
.inc-tasc-gradient-btn {
  background-color: linear-gradient(
    to right,
    #28dd7a 0%,
    #185a9d 51%,
    #45ce43 100%
  );
  font-size: 48px;
  font-weight: 600;
  font-family: "Work Sans", sans-serif;
}

.fs-48 {
  font-size: 48px;
}

.fs-36 {
  font-size: 36px;
}

.fs-30 {
  font-size: 30px;
}

.fs-24 {
  font-size: 24px;
}

.fs-13 {
  font-size: 13px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fw-600 {
  font-weight: 600;
}

.inc-auth-container {
  background-image: url("/public/authbg.svg");
  background-size: 100%;
}

.auth-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otpInput {
  border: 1px solid #c9cccf;
  border-radius: 8px;
}

.otpInput::-webkit-inner-spin-button,
.otpInput::-webkit-outer-spin-button,
.phone-input::-webkit-inner-spin-button,
.phone-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.incorpify-primary-btn {
  /* background-color: #5AC063; */
  color: #eee;
  font-weight: 500;
  height: 40px;
}

.incorpify-secondary-btn {
  color: #ff6221;
  border: 1px solid #ff6221;
  height: 40px;
  background: transparent;
  border-radius: 10px;
}

.incorpify-secondary-btn:hover {
  color: #928f8f !important;
  border-color: #928f8f !important;
  background: transparent;
}

.btn-outline-custom {
  outline: 1px solid #ff6221;
  color: #ff6221;
  cursor: pointer;
  /* font-size: 12px; */
  padding: 5px 30px;
  font-family: montserrat, sans-serif;
  /* font-weight: 500; */
}
.btn-outline-custom:hover {
  background: #ff6221;
  outline: 1px solid #ff6221;
  color: white;
}

.btn-outline-Show-more {
  color: #ff6221;
  cursor: pointer;
  font-size: 12px;
  padding: 5px 30px;
  border-radius: 50px;
  font-family: montserrat, sans-serif;
  background: none;
  border: 1px solid #ff6221;
}

.btn-outline-Show-more:hover {
  background: #ff6221;
  color: white;
}

.header-heading1 {
  font-size: 56px;
}
.btn-gradiant:hover {
  /* background: #016cff; change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
.header-search-input {
  font-size: 12px !important;
  padding: 10px 15px;
}

.track-card.bg-light-success {
  background: #d5f9e2;
}
.track-card.bg-light-success > .track-profession {
  color: rgb(31, 192, 91);
}

.track-card.bg-light-progress {
  background: #fcefbe;
}
.track-card.bg-light-progress > .track-profession {
  color: rgb(244, 152, 31);
}
.track-card.bg-light-danger {
  background: #f4bab6;
}
.track-card.bg-light-danger > .track-profession {
  color: rgb(225, 64, 20);
}
.track-card.bg-light-default {
  background: #8080803b;
}
.track-card.bg-light-default > .track-profession {
  color: grey;
}
.secondry-heading {
  font-size: 19px;
}
.btn-to-link {
  gap: 6px;
}
.icon-btn_track img {
  display: block;
  height: 100%;
  max-height: 20px;
  max-width: 20px;
  margin: auto;
}
.animation-container {
  position: relative;
  overflow: hidden;
  /* Ensure content does not overflow */
}
.animation-content {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  transform: translateX(100%);
  transform-origin: right;
  opacity: 0;
  transition: transform 1s ease 0.5s, opacity 1s ease 0.5s;
}

.animation-content.show {
  opacity: 1;
  transform: translateX(0);
}
.fs-s {
  font-size: 14px;
}
.head-color {
  color: rgb(3, 96, 132);
}
.bg-warning {
  background: rgb(244, 152, 31) !important;
}
.text-warning {
  color: rgb(244, 152, 31) !important;
}

.bg-inc-orange {
  background-color: #ff6221;
}
.text-inc-orange {
  color: #ff6221;
}

.text-inc-tundora {
  color: #434343;
}

.cursor-pointer {
  cursor: pointer;
}

.form-container {
  padding: 30px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
}

.input-group {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
}

.form-control {
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.custom-select:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.custom-select .ant-select-selector {
  background: rgb(232, 240, 254) !important;
  border-radius: 50px;
  border: none !important;
  padding: 20px 15px !important;
}

.custom-select-focus .ant-select-selector {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
}

.custom-select .ant-select-selection-search input {
  padding-left: 2px !important;
}

.custom-select .ant-select-selection-placeholder {
  color: #454545;
}

.custom-select .ant-select-arrow {
  padding: 10px 3px 0 0;
  color: #454545;
}

.region-select:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  box-shadow: 0 0 0 0 !important;
}

.region-select .ant-select-selector {
  background: #F7F7F7 !important;
  border-radius: 50px;
  border: none !important;
  box-shadow: none !important;
  padding: 20px 15px !important;
}

.region-select .ant-select-selection-search input {
  padding-left: 2px !important;
}

.region-select .ant-select-selection-placeholder {
  color: #454545;
}

.region-select .ant-select-arrow {
  padding: 10px 3px 0 0;
  color: #454545;
}

.countryOption {
  display: flex;
  gap: 10px;
  align-items: center;
}

.countryOption svg {
  width: 24px !important;
  height: auto;
}

input::placeholder {
  color: #454545;
}

.encrypted i::before {
  vertical-align: middle;
}

.form-auth input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  border-radius: 50px;
  padding: 10px 15px;
  font-size: 14px;
  background: #e8f0fe;
  border: 0px;
}

.password-input {
  justify-content: center;
  border-radius: 10px;
  padding: 15px;
  font-size: 14px;
  background: #dbdbdb;
  border: 0px;
}

.form-auth > button {
  display: flex;
  justify-content: center;
  border-radius: 50px;
  padding: 14px;
  font-size: 14px;
  border: 0px;
}
.btn-copy:active {
  color: green !important;
}
#users-list-table td,
#users-list-table th {
  max-width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#users-list-table td span,
#users-list-table th span {
  /* max-width: 160px; */
  white-space: nowrap;
  overflow: hidden;
  /* text-overflow: ellipsis; */
}
.dt-length > label {
  margin-left: 15px;
}

.primary-shadow {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.remove-notification:active {
  color: black !important;
}
.navigation-buttons {
  border: none;
  outline: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-color: transparent;
}
.navigation-buttons:active {
  outline: none;
  color: #016cff;
}
.ff-hind {
  font-family: "Hind", sans-serif;
}
.ff-ws {
  font-family: "Work Sans", sans-serif;
}
.ff-mont {
  font-family: "Montserrat";
}
button * {
}
.btn-to-link * {
  font-size: 12px;
  font-family: "Montserrat" !important;
}
.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.incorpify-bg-light-blue {
  background-color: #83acf1;
}

.inc-verify-user {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.inc-verify-user div {
  font-size: 24px;
  color: #20681f;
  background-color: #c4f3c4;
  padding: 24px;
  border-radius: 10px;
  outline: 1px solid #20681f;
}

.show-more-btn {
  /* padding: 20px; */
  border: 1px solid #005f83;
  color: #005f83;
  background: transparent;
  /* font-size: 16px; */
}

/* accordion css */
.accordion {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.accordion-checkbox {
  display: none;
}

.accordion-label {
  display: block;
  padding-top: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out, padding 0.5s ease-out;
  box-sizing: border-box;
}

.accordion-checkbox:checked + .accordion-label + .accordion-content {
  max-height: 500px;
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
}

/* end accordion css */

.ant-drawer-body {
  padding: 0 !important;
}

.show-more-btn:hover {
  background: #005f83 !important;
  color: #ffffff !important;
}

#iframe-signup > iframe {
  height: 100%;
  width: 1000px !important;
  border: 0px !important;
  box-shadow: none !important;
}
.navigation-buttons > i::before {
  position: relative;
  top: -7px;
}
#bottom-form > p.p-0.m-0 {
  padding-top: 10px;
}
#search-button > i::before {
  position: relative;
  top: -5px;
}

.default {
  color: #928f8f;
}

.logout-btn::after {
  content: "!";
  position: absolute;
  right: -10px;
  top: -13px;
  padding: 4px 12px;
  background: #ff9e2f;
  font-weight: 600;
  border-radius: 50%;
  height: 26px;
  animation: fill-animation 1s infinite;
}

.learn-btn::after {
  content: "!";
  position: absolute;
  right: -10px;
  top: -13px;
  padding: 4px 12px;
  background: #ff9e2f;
  font-weight: 600;
  border-radius: 50%;
  height: 26px;
  animation: fill-animation 1s infinite;
}

.request-btn::after {
  content: "!";
  position: absolute;
  right: -10px;
  top: -13px;
  padding: 4px 12px;
  background: #ffffff;
  color: #ffffff;
  font-weight: 600;
  border-radius: 50%;
  height: 26px;
  animation: fill-animation 1s infinite;
}

.incorpify-card-button::after {
  content: "!";
  position: absolute;
  right: -10px;
  top: -13px;
  padding: 4px 12px;
  background: #ff9e2f;
  font-weight: 600;
  border-radius: 50%;
  height: 26px;
  animation: fill-animation 1s infinite;
}
.show-all::after {
  content: "!";
  position: absolute;
  right: -10px;
  top: -13px;
  padding: 4px 12px;
  background: #ff9e2f;
  color: #ffffff;
  font-weight: 600;
  border-radius: 50%;
  height: 26px;
  animation: fill-animation 1s infinite;
}

@keyframes fill-animation {
  0% {
    background: radial-gradient(circle, #ff9e2f 50%, transparent 80%);
  }
  50% {
    background: radial-gradient(circle, #ff9e2f 100%, transparent 100%);
  }
  100% {
    background: radial-gradient(circle, #ff9e2f 40%, transparent 80%);
  }
}

.card-image {
  background: rgb(255, 98, 33);
  display: inline-block;
  padding: 20px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

/* carousel css start */

.carousel-container {
  position: relative;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #005f83;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
}

.custom-arrow.left {
  left: -20px; /* Adjust this value to position the arrow outside the carousel */
}

.custom-arrow.right {
  right: -30px; /* Adjust this value to position the arrow outside the carousel */
}

.carousel-slide-wrapper {
  padding: 0 8px;
}

.slick-list {
  margin: 0 30px !important;
}

.carousel-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

/* carousel css end */

.incorpify-title {
  font-size: 26px;
}

.ant-modal-title {
  padding-top: 5px;
}

.incorpify-footer-text {
  font-size: 13px;
  color: #434343;
}

.card-title-wrapper {
  margin-top: 30px;
}

.card-title {
  font-size: 26px;
  font-weight: 700;
  max-width: 400px;
  margin: 0 auto;
}

.incorpify-stepcard {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.step-col1 {
  width: 15%;
}
.step-col2 {
  width: 50%;
}
.step-col3 {
  width: 15%;
  margin-left: auto;
}
.incorpify-request-heading {
  width: 15%;
}

.tasc-custom-menu li {
  background-color: transparent !important;
}

.step-col4 {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.incorpify-update-input:focus {
  border: 1px solid #185a9d;
}

/* .incorpify-text-editor,  */
.incorpify-comment-section {
  padding: 20px 0;
  border-radius: 10px;
}

.inc-detail-container {
  padding: 30px;
}

.incorpify-text-editor .ql-container {
  min-height: 80px;
}

.ant-modal-content {
  padding: 0px !important;
}

.region-modal .ant-modal-content {
  padding: 20px 24px !important;
}

.product-search-form:focus-within {
  outline: 1px solid #3b99fc;
}

/* custom checkbox css starts */

.custom-checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  line-height: 0;
  font-size: 22px;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox .checkmark {
  width: 22px;
  height: 22px;
  background-color: #000;
  border: 2px solid #000;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  transition: background-color 0.3s, border-color 0.3s;
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  background-color: #28a745;
  border-color: #28a745;
}

.custom-checkbox .checkmark:before {
  content: "";
  position: absolute;
  display: block;
  top: 20%;
  left: 55%;
  width: 10px;
  height: 6px;
  border: solid white;
  border-width: 0 0 2px 2px;
  transform: rotate(-45deg) translate(-50%, -50%);
}

/* custom checkbox css end */

.tasc-invite-form-modal .ant-modal-content {
  border-radius: 0px;
}

.step {
  font-size: 78px;
}

.inc-comment-header {
  align-items: center;
  justify-content: space-between;
}

.service__form-modal {
  /* border-radius: 8px; */
}

.service__form-modal iframe {
  width: 100%;
  height: 100%;
}

.img-input {
  display: block;
  width: 100%;
  /* padding: 0.375rem 0.75rem; */
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.spinner {
  width: 60px;
  height: 60px;
  border: 8px solid #005f83;
  border-top: 8px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.inc-company-inputs {
  background: "#F3F4F8CC";
  font-size: "11px";
  margin: "12px 0px 0px";
  padding: "10px 17px";
  color: "rgb(44, 46, 56)";
}

.ant-popover-inner {
  /* background: #E7E7E7 !important; */
  margin-top: 25px;
  padding: 0 !important;
}

.inc-service-form .ant-select-selection-placeholder {
  font-size: 14px !important;
  /* color: #2c2e388a; */
}

.inc-reply-container p {
  margin: 0;
}

.inc-start-form__heading {
  font-size: 36px;
}

.incorpify-text-editor .ql-toolbar {
  border-radius: 8px 8px 0 0;
}
.incorpify-text-editor .ql-container {
  border-radius: 0 0 8px 8px;
}

.emoji-container {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  z-index: 500;
}

.reply-container {
  max-height: 400px;
  overflow-y: auto;
}

.message-container p {
  margin-bottom: 0;
}

.governify-footer-wrapper {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.inc-main-footer {
  width: 100%;
  /* max-width: 1296px; */
  justify-content: space-between;
  margin: auto;
  margin-top: 100px;
  border-top: 2px solid #ececec;
  padding: 20px;
  flex-wrap: wrap;
  align-items: center;
}

@media (max-width: 767px) {
  .inc-main-footer {
    flex-direction: column-reverse;
  }

  .inc-powered {
    margin-top: 10px;
  }
  .btn.btn-gradiant {
    min-width: 30px;
  }
  main.px-3.pt-5 {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin: 0px;
  }
  .nav-masthead {
    margin-top: 20px;
  }
  #iframe-signup {
    width: auto;
  }
  nav.nav-masthead > span.text-secondary {
    width: 100% !important;
  }
  .header-bar > .container {
    flex-direction: column;
  }
  .header-bar > .container > nav {
    display: flex;
    justify-content: end !important;
    gap: 10px;
    width: 100%;
    align-items: center !important;
  }
  /* #log-btn {
		order: 1;
	} */
  .nav.nav-masthead > span.text-secondary:first-child {
    width: auto;
    margin-bottom: 0px;
  }
  .header-logo > img {
    height: 60px;
  }
  #iframe-signup > iframe {
    max-width: 100% !important;
  }
  .header-heading1 {
    font-size: 35px;
  }
  /* p.secondry-heading {
		font-size: 13px !important;
	} */
  .animation-content {
    position: static !important;
  }
  #bottom-form {
    flex-wrap: wrap;
    align-items: center;
  }
  #bottom-form .dropdown {
    width: 100%;
  }
  .cover-container {
    padding: 0px !important;
  }
  .onboarding-text-center {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }
  .onboarding-rounded-circle {
    width: 100px;
    height: 100px;
  }

  .onboarding-flexcolumn {
    flex-direction: column;
  }

  .onboarding-width {
    width: 100%;
  }

  .onboarding-paddingtop {
    padding-top: 0px !important;
  }

  .onboardig-marginbottom {
    margin-bottom: 5px;
  }

  .onboarding-fs-24 {
    font-size: 24px !important;
  }

  .onboarding-fs-20 {
    font-size: 20px !important;
  }

  .onboarding-fs-14 {
    font-size: 14px !important;
  }

  .onboardin-padding-24 {
    padding-left: 24px !important;
  }

  .onboarding-text-align-left {
    text-align: left;
  }

  .onboarding-display-none::before {
    display: none;
  }

  .onboarding-padding-left-0 {
    padding-left: 0px !important;
  }

  .onboarding-display-block {
    display: block;
  }

  .onboarding-margin-top-16 {
    margin-top: 16px !important;
  }

  .onboarding-font-size-30 {
    font-size: 30px;
  }

  .onboarding-margin-top-18 {
    margin-top: 18px !important;
  }

  .onboarding-min-height-120 {
    min-height: 120px !important;
  }

  .onboarding-text-align-end {
    text-align: end;
  }
  /* .card-container {
		margin-top: 70px;
	} */
  .incorpify-stepcard {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 40px;
  }
  .step-col1,
  .step-col2,
  .step-col3 {
    width: 100%;
  }
  .step-col4 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  /* .incorpify-text-editor, */
  .incorpify-comment-section {
    padding: 20px 0;
  }
  .inc-detail-container {
    padding: 20px 10px;
  }
  .incorpify-request-heading {
    width: 20%;
  }
  .step {
    font-size: 67px;
  }
  .inc-start-form__heading {
    font-size: 33px;
  }
  /* #notification-banner {
		display: none;
	} */
}
@media (min-width: 768px) and (max-width: 991px) {
  /* #notification-banner {
		display: none;
	} */
  .card-title {
    font-size: 24px;
    font-weight: 700;
  }
  /* .incorpify-text-editor, */
  .incorpify-comment-section {
    padding: 20px 0;
  }
  .inc-detail-container {
    padding: 20px;
  }
}
@media (min-width: 992px) {
}
@media (max-width: 768px) {
}

@media (min-width: 500px) and (max-width: 769px) {
  .onboarding-text-center {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }

  .onboarding-button {
    display: flex;
    justify-content: space-evenly;
  }

  .onboarding-rounded-circle {
    width: 100px;
    height: 100px;
  }

  .onboarding-flexcolumn {
    flex-direction: column;
  }

  .onboarding-width {
    width: 100%;
  }

  .onboarding-paddingtop {
    padding-top: 0px;
  }

  .onboardig-marginbottom {
    margin-bottom: 5px;
  }

  .onboarding-fs-20 {
    font-size: 20px !important;
  }

  .onboarding-fs-14 {
    font-size: 14px !important;
  }

  .onboarding-fs-24 {
    font-size: 24px !important;
  }

  .onboardin-padding-24 {
    padding-left: 24px;
  }

  .onboarding-text-align-left {
    text-align: left;
  }

  /* #notification-banner, */
  .card-container {
    display: none;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
  }
}

@media (max-width: 600px) {
  .card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 100px;
  }
  .card-image {
    background: rgb(255, 98, 33);
    display: inline-block;
    padding: 20px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .inc-request__head-wrapper {
    flex-direction: column;
    gap: 40px;
  }
  .inc-request__title-wrapper {
    flex-direction: column;
    gap: 40px;
  }
  .inc-back-btn {
    margin-left: auto;
  }
  .step {
    font-size: 56px;
  }
  .inc-comment-header {
    align-items: start;
    justify-content: start;
    flex-direction: column;
    /* gap: 10px; */
  }
  .inc-comment-header div:nth-child(2) {
    margin-left: 15px;
  }
  .carousel-slide-wrapper {
    padding: 0 5px;
  }

  .slick-list {
    margin: 0 16px !important;
  }

  .custom-arrow.right {
    right: -20px; /* Adjust this value to position the arrow outside the carousel */
  }
}

@media (min-width: 500px) and (max-width: 999px) {
  .onboarding-rounded-circle {
    width: 100px;
    height: 100px;
  }
  .card-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card-image {
    background: rgb(255, 98, 33);
    display: inline-block;
    padding: 20px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .container {
    max-width: 100%;
  }
}
@media (min-width: 1400px) {
  /* .container {
    max-width: 1200px !important;
  } */
  .card-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 320px) and (max-width: 478px) {
  .onboarding-rounded-circle {
    width: 100px;
    height: 100px;
  }
  .card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 100px;
  }
  /* #notification-banner {
		display: none;
	} */
  .nav {
    display: none;
  }
  .card-image {
    background: rgb(255, 98, 33);
    display: inline-block;
    padding: 20px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .incorpify-title {
    font-size: 22px;
  }
  .incorpify-footer-text {
    font-size: 12px;
  }
  .card-title {
    font-size: 22px;
    font-weight: 700;
  }
  .inc-start-form__heading {
    font-size: 30px;
  }
}

@media (max-width: 540px) {
  .emoji-wrapper {
    display: none;
  }

  .form-container {
    padding: 20px;
  }
}
